import { defineNuxtPlugin } from '#app'
import { useGqlError } from '#imports'
import type { GqlError } from 'nuxt-graphql-client'

export type GqlErrorHandler = (error: unknown) => string

export interface GraphQLResponse {
  errors?: Array<{
    message: string
    extensions?: {
      message?: string
    }
  }>
  data?: Record<string, any>
}

export default defineNuxtPlugin(() => {
  const gqlErrorHandler: GqlErrorHandler = (error: unknown) => {
    if (error && typeof error === 'object' && 'gqlErrors' in error) {
      const gqlError = error as GqlError
      return (
        gqlError.gqlErrors[0]?.extensions?.message ||
        gqlError.gqlErrors[0]?.message ||
        'An error occurred'
      )
    }
    if (error && typeof error === 'object' && 'errors' in error) {
      const response = error as GraphQLResponse
      return (
        response.errors?.[0]?.extensions?.message ||
        response.errors?.[0]?.message ||
        'An error occurred'
      )
    }
    return error instanceof Error ? error.message : 'An unknown error occurred'
  }

  useGqlError(gqlErrorHandler)

  return {
    provide: {
      gqlErrorHandler,
    },
  }
})
