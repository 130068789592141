import { createInput } from '@formkit/vue'

const numberInputSchema = [
  {
    $el: 'button',
    children: [{ $el: 'span', children: '-' }],
    attrs: {
      class: '$classes.decrement',
      onClick: '$handlers.decrement()',
      type: 'button',
    },
  },
  {
    $el: 'input',
    bind: '$attrs',
    attrs: {
      id: '$id',
      name: '$name',
      type: 'number',
      class: '$classes.input',
      onInput: '$handlers.DOMInput',
      onBlur: '$handlers.blur',
      disabled: '$disabled',
      value: '$_value',
    },
  },
  {
    $el: 'button',
    children: [{ $el: 'span', children: '+' }],
    attrs: {
      class: '$classes.increment',
      onClick: '$handlers.increment()',
      type: 'button',
    },
  },
]

function addHandlers(node: any) {
  node.on('created', () => {
    function createStepHandler(step: any) {
      return () => () => {
        const value = parseInt(node.value, 10) ?? 0
        const newValue = value + step

        if (
          node.context.disabled ||
          (step > 0 && node.context.max && newValue > node.context.max) ||
          (step < 0 && node.context.min && newValue < node.context.min)
        ) {
          return
        }

        node.input(newValue)

        node.on('settled', () => {
          node.context.handlers.blur()
        })
      }
    }

    node.context.handlers.increment = createStepHandler(node.context.step ?? 1)
    node.context.handlers.decrement = createStepHandler(
      -(node.context.step ?? 1)
    )
  })

  node.on('dom-input-event', () => {
    const value = parseInt(node._value, 10)
    const step = parseFloat(node.context.step) || 1
    const min = parseFloat(node.context.min)
    const max = parseFloat(node.context.max)

    let newValue = value

    if (!isNaN(min) && value < min) {
      newValue = min
    } else if (!isNaN(max) && value > max) {
      newValue = max
    } else {
      // Round to nearest step
      newValue = Math.round(value / step) * step
    }

    if (newValue !== value) {
      node.input(newValue)
    }
  })
}

export default createInput(numberInputSchema, {
  features: [addHandlers],
  props: ['disabled', 'min', 'max', 'step'],
})
